import React from "react";
// import sections
import AboutSection from "../components/sections/AboutSection";
const About = () => {
  return (
    <>
      <AboutSection />
    </>
  );
};

export default About;
