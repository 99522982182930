import React from "react";
// import sections
import TermsSection from "../components/sections/TermsSection";
const Terms = ({ ...props }) => {
  return (
    <>
      <TermsSection {...props} />
    </>
  );
};

export default Terms;
