import React from "react";
import classNames from "classnames";

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a href="https://www.facebook.com/vwolvessolutions">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Facebook</title>
              <path d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/vwolves">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>LinkedIn</title>
              <path d="M 12.667969 0 L 3.332031 0 C 1.492188 0 0 1.492188 0 3.332031 L 0 12.667969 C 0 14.507812 1.492188 16 3.332031 16 L 12.667969 16 C 14.507812 16 16 14.507812 16 12.667969 L 16 3.332031 C 16 1.492188 14.507812 0 12.667969 0 Z M 5.332031 12.667969 L 3.332031 12.667969 L 3.332031 5.332031 L 5.332031 5.332031 Z M 4.332031 4.488281 C 3.6875 4.488281 3.167969 3.960938 3.167969 3.3125 C 3.167969 2.664062 3.6875 2.136719 4.332031 2.136719 C 4.976562 2.136719 5.5 2.664062 5.5 3.3125 C 5.5 3.960938 4.976562 4.488281 4.332031 4.488281 Z M 13.332031 12.667969 L 11.332031 12.667969 L 11.332031 8.929688 C 11.332031 6.683594 8.667969 6.855469 8.667969 8.929688 L 8.667969 12.667969 L 6.667969 12.667969 L 6.667969 5.332031 L 8.667969 5.332031 L 8.667969 6.511719 C 9.597656 4.785156 13.332031 4.660156 13.332031 8.160156 Z M 13.332031 12.667969 " />
            </svg>
          </a>
        </li>
        {/* <li>
          <a href="https://twitter.com/">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <title>Twitter</title>
              <path
                d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://google.com/">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <title>Instagram</title>
              <g>
                <circle cx="12.145" cy="3.892" r="1" />
                <path
                  d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                <path
                  d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z" />
              </g>
            </svg>
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default FooterSocial;
