import React from "react";
import classNames from "classnames";
import VNavLink from "./VNavLink";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);
  const setActive = props?.setactive;
  return (
    <nav className={classes}>
      <ul className="list-reset">
        <li>
          <VNavLink
            from="/terms"
            name="Terms and Conditions"
            to="terms"
            href="/terms"
            setActive={setActive}
          />
        </li>
        <li>
          <VNavLink
            from="/policy"
            name="Privacy Policy"
            to="policy"
            href="/policy"
            setActive={setActive}
          />
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
