import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import ContactUs from "../components/sections/ContactUs";
import Products from "../components/sections/Products";
import Clients from "../components/sections/Clients";
import Websites from "../components/sections/Websites";
import Overview from "../components/sections/Overview";
import Learn from "../components/sections/Learn";

const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <Overview />
      <Products
        invertMobile
        topDivider
        imageFill
        className="illustration-section-02"
      />
      <Websites topDivider />
      <Clients topDivider />
      <Learn topDivider />
      <ContactUs />
    </>
  );
};

export default Home;
