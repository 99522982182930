import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { scroller } from "react-scroll";

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const [active, setActive] = useState(null);

  useEffect(() => {
    if (active) {
      scroller.scrollTo(active, {
        spy: true,
        smooth: true,
        offset: 0,
        duration: 500,
      });
    }
  }, [active]);

  Layout = Layout === undefined ? (props) => <>{props.children}</> : Layout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout setactive={setActive} active={active}>
          <Component {...props} setactive={setActive} active={active} />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
