import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Filter1RoundedIcon from "@mui/icons-material/Filter1Rounded";
import Filter2RoundedIcon from "@mui/icons-material/Filter2Rounded";
import Filter3RoundedIcon from "@mui/icons-material/Filter3Rounded";
import Filter4RoundedIcon from "@mui/icons-material/Filter4Rounded";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Overview = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#676834",
      },
    },
  });
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const overView = {
    title: "Overview",
    paragraph:
      "At VWolves, we understand that everyone has their own unique challenges and requirements. There is no such thing as a one size fits all solution.",
    sub: "We will work with you through all of the steps necessary to provide the best possible solution for you to stay competitive and ahead of the competition.",
  };
  const howItWorks = {
    title: "How It Works",
  };

  return (
    <section {...props} className={outerClasses} id="overview">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={overView}
            className="center-content reveal-from-bottom"
            data-reveal-delay="100"
          />
          <SectionHeader
            data={howItWorks}
            className="center-content reveal-from-bottom"
            data-reveal-delay="200"
          />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <ThemeProvider theme={theme}>
                      <Filter1RoundedIcon color="primary" fontSize="large" />
                    </ThemeProvider>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Brainstorm</h4>
                  <h6 className="m-0">Understanding your needs</h6>
                  <p className="m-0 text-sm">
                    Working with you to identify the pain points. Whether it be
                    as simple as having a strong online presence, transition to
                    upgrade to existing system, or a complete automation of your
                    processes.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <ThemeProvider theme={theme}>
                      <Filter2RoundedIcon color="primary" fontSize="large" />
                    </ThemeProvider>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Solutions</h4>
                  <h6 className="m-0">Know your options</h6>
                  <p className="m-0 text-sm">
                    Continuously providing you options that fits your need. The
                    world of technology is quickly evolving day by day. A
                    technology today may not be the best tomorrow. We will help
                    you stay up to date and level up ahead your competition.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <ThemeProvider theme={theme}>
                      <Filter3RoundedIcon color="primary" fontSize="large" />
                    </ThemeProvider>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Execution</h4>
                  <h6 className="m-0">Painless implementation</h6>
                  <p className="m-0 text-sm">
                    Enabling your technology needs. Highly experienced IT
                    engineers, working with you all the way to continuously
                    implement your technology infrastructure. We will configure
                    your system to fit your specific needs.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <ThemeProvider theme={theme}>
                      <Filter4RoundedIcon color="primary" fontSize="large" />
                    </ThemeProvider>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Support</h4>
                  <h6 className="m-0">Keeping the cogs in place</h6>
                  <p className="m-0 text-sm">
                    Making sure everything is running smoothly. Handling your
                    software issues, system upgrades, and training your users.
                    We are here to support and guide you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Overview.propTypes = propTypes;
Overview.defaultProps = defaultProps;

export default Overview;
