import React from "react";
// import sections
import PolicySection from "../components/sections/PolicySection";
const Policy = ({ ...props }) => {
  return (
    <>
      <PolicySection {...props} />
    </>
  );
};

export default Policy;
