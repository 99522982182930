import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Clients = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Our Clients",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Image
                    src={require("./../../assets/images/IslandsMoringa.png")}
                    alt="IM"
                    width={200}
                  />
                  <br />
                  <div className="center-content">
                    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                      - E-commerce website
                    </div>
                    <h6 className="mt-0 mb-12">Island's Moringa</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Image
                    src={require("./../../assets/images/nw.png")}
                    alt="NW"
                    width={200}
                    className="align-center"
                  />
                  <br />
                  <div className="center-content">
                    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                      - E-commerce website
                    </div>
                    <h6 className="mt-0 mb-12">Ninong Wren</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Image
                    src={require("./../../assets/images/twenty4.png")}
                    alt="t4"
                    width={200}
                    className="align-center"
                  />
                  <br />
                  <div className="center-content">
                    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                      - E-commerce website
                    </div>
                    <h6 className="mt-0 mb-12">Twenty4</h6>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Image
                    src={require("./../../assets/images/wismon.png")}
                    alt="WC"
                    width={200}
                  />
                  <br />
                  <div className="center-content">
                    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                      - Custom automation software solutions
                    </div>
                    <h6 className="mt-0 mb-12">Wismon</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Clients.propTypes = propTypes;
Clients.defaultProps = defaultProps;

export default Clients;
