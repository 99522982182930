import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Link } from "react-scroll";

const VNavLink = (props) => {
  let { from, name, to, closeMenu, href, setActive, className } = props;
  let history = useHistory();
  const location = history?.location?.pathname;

  return (
    <>
      {location === from ? (
        <Link
          href=""
          to={to}
          onClick={closeMenu}
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className={className}
        >
          {name}
        </Link>
      ) : (
        <NavLink
          to={href}
          onClick={(e) => {
            setActive && setActive(to);
            closeMenu && closeMenu();
          }}
          className={className}
        >
          {name}
        </NavLink>
      )}
    </>
  );
};

export default VNavLink;
