import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ModeStandbyRoundedIcon from "@mui/icons-material/ModeStandbyRounded";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const About = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#676834",
      },
    },
  });
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const overView = {
    title: "About Us",
    paragraph:
      "VWolves is a software development company founded by individuals with strong software engineering backgrounds. With the experience of the team in building software solutions in several industries, we have seen the impact of the advancement of these technologies in developed countries. We want to take these solutions and cater it to the needs of developing countries in order to help improve the lives of everyone.",
  };
  const whoWeAre = {
    title: "Who We Are",
  };

  return (
    <section {...props} className={outerClasses} id="about">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={overView}
            className="center-content reveal-from-bottom"
            data-reveal-delay="100"
          />
          <SectionHeader
            data={whoWeAre}
            className="center-content reveal-from-bottom"
            data-reveal-delay="200"
          />
          <div className={tilesClasses}>
            <div className="tiles-item-about reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <ThemeProvider theme={theme}>
                      <ModeStandbyRoundedIcon
                        color="primary"
                        fontSize="large"
                      />
                    </ThemeProvider>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Our Mission</h4>
                  <p className="m-0 text-sm">
                    Create income opportunities for individuals and businesses
                    by creating an ecosystem that serves the needs of customers
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item-about reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <ThemeProvider theme={theme}>
                      <VisibilityRoundedIcon color="primary" fontSize="large" />
                    </ThemeProvider>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Our Vission</h4>
                  <p className="m-0 text-sm">
                    {`Help improve people’s quality of life in developing
                    countries`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;
