import React, { useRef, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Input from "../elements/Input";
import Button from "../elements/Button";
import Modal from "../elements/Modal";
import emailjs from "@emailjs/browser";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const ContactUs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successModal, showSucessModal] = useState(false);
  const [errorModal, showErrorModal] = useState(false);
  const closeModal = (e) => {
    e.preventDefault();
    showSucessModal(false);
    showErrorModal(false);
  };

  const clearFields = () => {
    setName("");
    setEmail("");
    setMessage("");
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const sendEmail = await emailjs.sendForm(
        "service_ordhfou",
        "template_0qxflxi",
        form.current,
        "q2vczfYGHJhFo_K1L"
      );
      if (sendEmail) {
        showSucessModal(true);
        clearFields();
      }
    } catch (error) {
      showErrorModal(true);
    }
    setLoading(false);
  };

  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Contact Us",
  };

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  return (
    <section {...props} className={outerClasses} id="contact">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="reveal-from-bottom contact-item">
              <form ref={form} onSubmit={(e) => sendEmail(e)}>
                <fieldset>
                  <div className="mb-16 ml-24 mr-24">
                    <Input
                      type="text"
                      placeholder="ex. John Doe"
                      label="Full name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      hint=""
                      status=""
                      required
                    />
                  </div>
                  <div className="mb-16 ml-24 mr-24">
                    <Input
                      type="email"
                      placeholder="ex. johndoe@email.com"
                      value={email}
                      label="Email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      status=""
                      hint=""
                      required
                    />
                  </div>
                  <div className="mb-16 ml-24 mr-24">
                    <Input
                      type="textarea"
                      placeholder="Your message here . . . ."
                      label="Message"
                      name="message"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      status=""
                      hint=""
                      required
                    />
                  </div>
                  <div className="mt-24 ml-24 mr-24">
                    <div className="button-group">
                      <Button loading={loading} color="primary">
                        Submit
                      </Button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={successModal} handleClose={closeModal}>
        <h2 className="mt-0 text-color-success">Message Sent!</h2>
        <p className="m-0">
          Thank you for contacting us! We will be in touch with you shortly.
        </p>
      </Modal>
      <Modal show={errorModal} handleClose={closeModal}>
        <h2 className="mt-0 text-color-error">Some error occurred!</h2>
        <p className="m-0">Please try again.</p>
      </Modal>
    </section>
  );
};

ContactUs.propTypes = propTypes;
ContactUs.defaultProps = defaultProps;

export default ContactUs;
