import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(["h1", "h2", "h3"]),
};

const defaultProps = {
  children: null,
  tag: "h2",
};

const SectionHeader = ({ className, data, children, tag, ...props }) => {
  const classes = classNames("section-header text-color-primary", className);

  const Component = tag;

  return (
    <>
      {(data.title || data.paragraph) && (
        <div {...props} className={classes}>
          <div className="container-xs">
            {children}
            {data.title && (
              <Component
                className={classNames(
                  "mt-0",
                  data.paragraph ? "mb-16" : "mb-0"
                )}
              >
                {data.title}
              </Component>
            )}
            {data.subtitle && (
              <div className="text-xxs text-color-primary fw-600 tt-u mb-8 center-content">
                {data.subtitle}
              </div>
            )}
            {data.paragraph && <sub className="m-0">{data.paragraph}</sub>}
            <br />
            {data.sub && <sub className="m-0">{data.sub}</sub>}
          </div>
        </div>
      )}
    </>
  );
};

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
