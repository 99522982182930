import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Learn = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Where do we start?",
    subtitle: "Free Consultation",
    paragraph: `Schedule your free consultation today and speak to one of our industry expert IT consultants.
    Ask all your IT related inquiries and we will help you understand what you need.
    The good thing is It’s free, no commitment. Gain insights with nothing to lose.
    `,
  };

  return (
    <section {...props} className={outerClasses} id="learn">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-bottom"
            data-reveal-delay="100"
          />
        </div>
      </div>
    </section>
  );
};

Learn.propTypes = propTypes;
Learn.defaultProps = defaultProps;

export default Learn;
