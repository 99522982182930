import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import { NavLink } from "react-router-dom";
import VNavLink from "../layout/partials/VNavLink";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const TermsSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const setActive = props?.setactive;
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section className={outerClasses} id="terms">
      <div className="container">
        <div className={innerClasses}>
          <div className="container-sm">
            <h2 className={classNames("mt-0", "mb-16")}>
              Terms And Conditions
            </h2>
            <sub className="m-0 mb-16 text-color-primary">
              Welcome to www.vwolves.com!
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              These terms and conditions outline the rules and regulations for
              the use of VWolves Solutions Incorporated's Website, located at
              https://www.vwolves.com.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              By accessing this website we assume you accept these terms and
              conditions. Do not continue to use www.vwolves.com if you do not
              agree to take all of the terms and conditions stated on this page.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice, and all Agreements:
              "Client", "You" and "Your" refers to you, the person who logs on
              to this website and is compliant with the Company’s terms and
              conditions. "The Company", "Ourselves", "We", "Our" and "Us",
              refers to our Company. "Party", "Parties", or "Us", refers to both
              the Client and ourselves. All terms refer to the offer,
              acceptance, and consideration of payment necessary to undertake
              the process of our assistance to the Client in the most
              appropriate manner for the express purpose of meeting the Client’s
              needs in respect of the provision of the Company’s stated
              services, in accordance with and subject to, prevailing law of
              Netherlands. Any use of the above terminology or other words in
              the singular, plural, capitalization, and he/she or they, are
              taken as interchangeable and therefore as referring to the same.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">Cookies</h4>
            <sub className="m-0 text-color-primary">
              We employ the use of cookies. By accessing www.vwolves.com, you
              agree to use cookies in agreement with VWolves Solutions
              Incorporated's Privacy Policy.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              Most interactive websites use cookies to let us retrieve the
              user’s details for each visit. Cookies are used by our website to
              enable the functionality of certain areas to make it easier for
              people visiting our website. Some of our affiliate/advertising
              partners may also use cookies.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">License</h4>
            <sub className="m-0 text-color-primary">
              Unless otherwise stated, VWolves Solutions Incorporated and/or its
              licensors own the intellectual property rights for all material on
              www.vwolves.com. All intellectual property rights are reserved.
              You may access this from www.vwolves.com for your own personal use
              subject to restrictions set in these terms and conditions.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">You must not:</sub>
            <br />
            <br />
            <ul>
              <li className="text-sub m-0 text-color-primary">
                Republish material from www.vwolves.com
              </li>
              <li className="text-sub m-0 text-color-primary">
                Sell, rent, or sub-license material from www.vwolves.com
              </li>
              <li className="text-sub m-0 text-color-primary">
                Reproduce, duplicate or copy material from www.vwolves.com
              </li>
              <li className="text-sub m-0 text-color-primary">
                Redistribute content from www.vwolves.com
              </li>
            </ul>
            <sub className="m-0 text-color-primary">
              This Agreement shall begin on the date hereof.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">
              Hyperlinking to our Content
            </h4>
            <sub className="m-0 text-color-primary">
              The following organizations may link to our Website without prior
              written approval:
            </sub>
            <br />
            <br />
            <ul>
              <li className="text-sub m-0 text-color-primary">
                Government agencies;
              </li>
              <li className="text-sub m-0 text-color-primary">
                Search engines;
              </li>
              <li className="text-sub m-0 text-color-primary">
                News organizations;
              </li>
              <li className="text-sub m-0 text-color-primary">
                Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses; and
              </li>
              <li className="text-sub m-0 text-color-primary">
                System-wide Accredited Businesses except soliciting non-profit
                organizations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Web site.
              </li>
            </ul>
            <sub className="m-0 text-color-primary">
              {`These organizations may link to our home page, to publications, or
              to other Website information so long as the link: (a) is not in
              any way deceptive; (b) does not falsely imply sponsorship,
              endorsement, or approval of the linking party and its products
              and/or services; and (c) fits within the context of the linking
              party’s site.`}
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              We may consider and approve other link requests from the following
              types of organizations:
            </sub>
            <br />
            <br />
            <ul>
              <li className="text-sub m-0 text-color-primary">
                commonly-known consumer and/or business information sources;
              </li>
              <li className="text-sub m-0 text-color-primary">
                dot.com community sites;
              </li>
              <li className="text-sub m-0 text-color-primary">
                associations or other groups representing charities;
              </li>
              <li className="text-sub m-0 text-color-primary">
                online directory distributors;
              </li>
              <li className="text-sub m-0 text-color-primary">
                internet portals;
              </li>
              <li className="text-sub m-0 text-color-primary">
                accounting, law, and consulting firms; and
              </li>
              <li className="text-sub m-0 text-color-primary">
                educational institutions and trade associations.
              </li>
            </ul>
            <sub className="m-0 text-color-primary">
              We will approve link requests from these organizations if we
              decide that: (a) the link would not make us look unfavorably to
              ourselves or to our accredited businesses; (b) the organization
              does not have any negative records with us; (c) the benefit to us
              from the visibility of the hyperlink compensates the absence of
              VWolves Solutions Incorporated, and (d) the link is in the context
              of general resource information.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              {`These organizations may link to our home page so long as the link:
              (a) is not in any way deceptive; (b) does not falsely imply
              sponsorship, endorsement, or approval of the linking party and its
              products or services; and (c) fits within the context of the
              linking party’s site.`}
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              If you are one of the organizations listed in paragraph 2 above
              and are interested in linking to our website, you must inform us
              by sending an e-mail to{" "}
              <VNavLink
                from="/"
                name="contact@vwolves.com"
                to="learn"
                href="/"
                setActive={setActive}
              />
              . Please include your name, your organization name, and contact
              information as well as the URL of your site, a list of any URLs
              from which you intend to link to our Website, and a list of the
              URLs on our site to which you would like to link. Wait 2-3 weeks
              for a response.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              Approved organizations may hyperlink to our Website as follows:
            </sub>
            <br />
            <br />
            <ul>
              <li className="text-sub m-0 text-color-primary">
                By use of our corporate name; or
              </li>
              <li className="text-sub m-0 text-color-primary">
                By use of the uniform resource locator being linked to; or
              </li>
              <li className="text-sub m-0 text-color-primary">
                {`By use of any other description of our Website being linked to
                that makes sense within the context and format of content on the
                linking party’s site.`}
              </li>
            </ul>
            <sub className="m-0 text-color-primary">
              No use of VWolves Solutions Incorporated's logo or other artwork
              will be allowed for linking absent a trademark license agreement.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">iFrames</h4>
            <sub className="m-0 text-color-primary">
              Without prior approval and written permission, you may not create
              frames around our Webpages that alter in any way the visual
              presentation or appearance of our Website.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">Content Liability</h4>
            <sub className="m-0 text-color-primary">
              We shall not be held responsible for any content on your Website.
              You agree to protect and defend us against all claims that are
              rising on your Website. No link(s) should appear on any Website
              that may be interpreted as libelous, obscene, or criminal, or
              which infringes, otherwise violates, or advocates the infringement
              or other violation of, any third-party rights.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">Your Privacy</h4>
            <sub className="m-0 text-color-primary">
              Please read <NavLink to="/policy">Privacy Policy</NavLink>
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">Reservation of Rights</h4>
            <sub className="m-0 text-color-primary">
              We reserve the right to request that you remove all links or any
              particular link to our Website. You approve of immediately
              removing all links to our Website upon request. We also reserve
              the right to amend these terms and conditions and its linking
              policy at any time. By continuously linking to our Website, you
              agree to be bound to and follow these linking terms and
              conditions.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">
              Removal of links from our website
            </h4>
            <sub className="m-0 text-color-primary">
              If you find any link on our Website that is offensive for any
              reason, you are free to contact and inform us at any moment. We
              will consider requests to remove links but we are not obligated to
              or so or to respond to you directly.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              We do not ensure that the information on this website is correct,
              we do not warrant its completeness or accuracy; nor do we promise
              to ensure that the website remains available or that the material
              on the website is kept up to date.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">Disclaimer</h4>
            <sub className="m-0 text-color-primary">
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties, and conditions relating to our
              website and the use of this website. Nothing in this disclaimer
              will:
            </sub>
            <br />
            <br />
            <ul>
              <li className="text-sub m-0 text-color-primary">
                limit or exclude our or your liability for death or personal
                injury;
              </li>
              <li className="text-sub m-0 text-color-primary">
                limit or exclude our or your liability for fraud or fraudulent
                misrepresentation;
              </li>
              <li className="text-sub m-0 text-color-primary">
                limit any of our or your liabilities in any way that is not
                permitted under applicable law; or
              </li>
              <li className="text-sub m-0 text-color-primary">
                exclude any of our or your liabilities that may not be excluded
                under applicable law.
              </li>
            </ul>
            <sub className="m-0 text-color-primary">
              The limitations and prohibitions of liability set in this Section
              and elsewhere in this disclaimer: (a) are subject to the preceding
              paragraph; and (b) govern all liabilities arising under the
              disclaimer, including liabilities arising in contract, in tort,
              and for breach of statutory duty.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              As long as the website and the information and services on the
              website are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </sub>
          </div>
        </div>
      </div>
    </section>
  );
};

TermsSection.propTypes = propTypes;
TermsSection.defaultProps = defaultProps;

export default TermsSection;
