import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import VNavLink from "../layout/partials/VNavLink";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const PolicySection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const setActive = props?.setactive;
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section className={outerClasses} id="policy">
      <div className="container">
        <div className={innerClasses}>
          <div className="container-sm">
            <h2 className={classNames("mt-0", "mb-16")}>Privacy Policy</h2>
            <sub className="m-0 mb-16 text-color-primary">
              At www.vwolves.com, accessible from https://www.vwolves.com, one
              of our main priorities is the privacy of our visitors. This
              Privacy Policy document contains types of information that is
              collected and recorded by www.vwolves.com and how we use it. If
              you have additional questions or require more information about
              our Privacy Policy, do not hesitate to{" "}
              <VNavLink
                from="/"
                name="contact us"
                to="learn"
                href="/"
                setActive={setActive}
              />
              .
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regard to the information
              that they shared and/or collect on www.vwolves.com. This policy is
              not applicable to any information collected offline or via
              channels other than this website.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">Consent</h4>
            <sub className="m-0 text-color-primary">
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">Information we collect</h4>
            <sub className="m-0 text-color-primary">
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">
              How we use your information
            </h4>
            <sub className="m-0 text-color-primary">
              We use the information we collect in various ways, including:
            </sub>
            <br />
            <br />
            <ul>
              <li className="text-sub m-0 text-color-primary">
                Provide, operate, and maintain our website
              </li>
              <li className="text-sub m-0 text-color-primary">
                Improve, personalize, and expand our website
              </li>
              <li className="text-sub m-0 text-color-primary">
                Understand and analyze how you use our website
              </li>
              <li className="text-sub m-0 text-color-primary">
                Develop new products, services, features, and functionality
              </li>
              <li className="text-sub m-0 text-color-primary">
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </li>
              <li className="text-sub m-0 text-color-primary">
                Send you emails
              </li>
              <li className="text-sub m-0 text-color-primary">
                Find and prevent fraud
              </li>
            </ul>
            <h4 className="m-0 text-color-primary">Log Files</h4>
            <sub className="m-0 text-color-primary">
              www.vwolves.com follows a standard procedure of using log files.
              These files log visitors when they visit websites. All hosting
              companies do this and are a part of hosting services' analytics.
              The information collected by log files include internet protocol
              (IP) addresses, browser type, Internet Service Provider (ISP),
              date and time stamp, referring/exit pages, and possibly the number
              of clicks. These are not linked to any information that is
              personally identifiable. The purpose of the information is for
              analyzing trends, administering the site, tracking users' movement
              on the website, and gathering demographic information.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">Cookies and Web Beacons</h4>
            <sub className="m-0 text-color-primary">
              Like any other website, www.vwolves.com uses 'cookies'. These
              cookies are used to store information including visitors'
              preferences, and the pages on the website that the visitor
              accessed or visited. The information is used to optimize the
              users' experience by customizing our web page content based on
              visitors browser type and/or other information.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">
              Advertising Partners Privacy Policies
            </h4>
            <sub className="m-0 text-color-primary">
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of www.vwolves.com.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              Third-party ad servers or ad networks uses technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on
              www.vwolves.com, which are sent directly to users' browser. They
              automatically receive your IP address when this occurs. These
              technologies are used to measure the effectiveness of their
              advertising campaigns and/or to personalize the advertising
              content that you see on websites that you visit.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              Note that www.vwolves.com has no access to or control over these
              cookies that are used by third-party advertisers.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">
              Third-Party Privacy Policies
            </h4>
            <sub className="m-0 text-color-primary">
              www.vwolves.com's Privacy Policy does not apply to other
              advertisers or websites. Thus, we are advising you to consult the
              respective Privacy Policies of these third-party ad servers for
              more detailed information. It may include their practices and
              instructions about how to opt out of certain options.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found on the browsers'
              respective websites.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">
              CCPA Privacy Rights (Do Not Sell My Personal Information)
            </h4>
            <sub className="m-0 text-color-primary">
              Under the CCPA, among other rights, California consumers have the
              right to:
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              Request that a business that collects a consumer's personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              Request that a business deletes any personal data about the
              consumer that a business has collected.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              Request that a business that sells a consumer's personal data, not
              sell the consumer's personal data.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">
              GDPR Data Protection Rights
            </h4>
            <sub className="m-0 text-color-primary">
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </sub>
            <br />
            <br />
            <h4 className="m-0 text-color-primary">Children's Information</h4>
            <sub className="m-0 text-color-primary">
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </sub>
            <br />
            <br />
            <sub className="m-0 text-color-primary">
              www.vwolves.com does not knowingly collect any Personal
              Identifiable Information from children under the age of 13. If you
              think that your child provided this kind of information on our
              website, we strongly encourage you to contact us immediately and
              we will do our best efforts to promptly remove such information
              from our records.
            </sub>
          </div>
        </div>
      </div>
    </section>
  );
};

PolicySection.propTypes = propTypes;
PolicySection.defaultProps = defaultProps;

export default PolicySection;
